.dashboard-shortcut-slider-container
  .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
  background-color: #fff;
  border: 1px solid #016cba;
  filter: drop-shadow(1px 1px 17px rgba(218, 216, 216, 0.33));
  right: calc(1% + 1px);
}

.dashboard-shortcut-slider-container
  .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
  background-color: #fff;
  border: 1px solid #016cba;
  left: calc(1% + 1px);
  filter: drop-shadow(1px 1px 17px rgba(218, 216, 216, 0.33));
}

.dashboard-shortcut-slider-container
  .react-multiple-carousel__arrow--left::before,
.dashboard-shortcut-slider-container
  .react-multiple-carousel__arrow--right::before {
  color: #000;
}

@media only screen and (max-width: 600px) {
  .dashboard-shortcut-slider-container
    .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right,
  .dashboard-shortcut-slider-container
    .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
    display: none;
  }
}
